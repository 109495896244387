import "./Tables.css"
import {ITables} from "../../interfaces/ISchemes";

export const getRect = (table: ITables, guests: number, setActiveTable: (active: string | null) => void, isResturantView?: boolean) => {
    const tableElement = document.getElementById(`Table_${table.number}`);
    const path = tableElement?.getElementsByTagName("rect")[0];
    const rect = tableElement?.getElementsByTagName("rect");    
    const point = tableElement?.getElementsByClassName('Point')[0];

    let x = Number(point?.getAttribute("x"));
    const y = Number(point?.getAttribute("y"));

    if (path) {
        path.style.fill = table.isFull ? "#B6CBDE" : table.isCloseRegistration ? "rgb(194,193,193)" :"#052039"
    }

    if (point && !table.numberOccupied) {
        (point as HTMLElement).style.fill = 'none'
    }

    if (rect) {
        if (table.numberOccupied !== 0) {
            (rect[1] as SVGRectElement).style.fill = "white"
        }

        if (table.numberOccupied ===  guests && isResturantView && guests > 0) {
            (rect[1] as SVGRectElement).style.fill = "#EE7605"
        }


        if (guests === 0 && table.numberOccupied === 0) {
            Array.from(rect).forEach((el, i) => {
                if (i !== 0) {
                    (el as SVGRectElement).style.fill = "none"
                }
            })
        }

        if (guests !== 0 && table.numberOccupied === 0) {
            (rect[1] as SVGRectElement).style.fill =  "#EE7605"
        }
    }

    if (tableElement) {

        tableElement.style.cursor = table.isFull ? "none" : "pointer"
        if (isResturantView) tableElement.style.cursor = "none"

        tableElement.onclick = () => {
            if (table.isFull || table.isCloseRegistration || isResturantView) return
            setActiveTable(String(table.number));

            const event = new CustomEvent('table', {
                detail: {
                    guid: table.guid || "",
                    number: table.number,
                    numberOccupied: table.numberOccupied || 0,
                    numberSeats: table.numberSeats || 8,
                },
            })

            document.dispatchEvent(event)

        };
    }

    const getFill = (index: number, rect: any) => {
        if(isResturantView) {

            if (index < table.numberOccupied - guests) {
                rect.style.fill = "white"
            }

            if (index >  table.numberOccupied - (guests + 1) && index < table.numberOccupied) {
                rect.style.fill = "#EE7605"
            }
        } else {
            if (index < table.numberOccupied) {
                rect.style.fill = "white"
            }

            if (index >=  table.numberOccupied && index < table.numberOccupied + guests) {
                rect.style.fill = "#EE7605"
            }

            if (index >= table.numberOccupied && index === table.numberOccupied + guests) {
                rect.style.fill = "none"
            }
        }

    }

    const rects = document.getElementById(`Table_${table.number}`)?.getElementsByClassName("rectNew")
    for(let i = Number(table.numberSeats)-1; i >= 0; i--){
        if(rects?.[i] !== undefined){
            rects[i].remove()
        }
    }

    for (let i = 1; i < table.numberSeats; i++) {
        const rect = document.createElementNS("http://www.w3.org/2000/svg", 'rect');
        rect.setAttribute("x", `${x ? x += 8 : 25}`);
        rect.setAttribute("y", `${y}`);
        rect.setAttribute("rx", "2.5");
        rect.classList.add("rectNew");
        getFill(i, rect)

        tableElement?.appendChild(rect);
    }
};
